<template>
  <section>
    <div
      class="box animated"
      v-bind:class="{
        shake: form.error,
        intensifies: form.explode,
        red: form.explode,
      }"
      @keyup.enter.exact="onSearch()"
    >
      <b-field :type="form.code.status" :message="form.code.message">
        <b-input
          type="text"
          v-model="form.code.data"
          placeholder="Enter Proof Number"
        ></b-input>
        <p class="control">
          <b-button
            type="is-primary"
            icon-right="angle-right"
            @click="onSearch()"
          >
            Find Proof
          </b-button>
        </p>
      </b-field>

      <b-message :type="form.status" v-if="form.result">
        {{ form.message }}
      </b-message>
    </div>
  </section>
</template>

<script>
import toast from "@/mixins/toast";
export default {
  name: "FindProof",
  mixins: [toast],
  data() {
    return {
      isLoading: false,
      token: null,
      form: {
        code: {
          data: "",
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
        countErrors: 0,
      },
    };
  },
  methods: {
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    onSearch() {
      if (this.formCheck("code")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/get_proofs.php`, {
            num: this.form.code.data,
            action: "findItem",
            token: localStorage.ddPST, 
          })
          .then((response) => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              //!!!redirect
              this.$router
                .push({ path: `proof/${response.data.urlCode }` })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.form.status = "is-danger";
              this.hasError();
              this.form.countErrors++;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    getToken() {
      console.log("Getting Token..");
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/token.php`, {
          action: "getToken",
        })
        .then((response) => {
          //console.log("RESPONCE:" + JSON.stringify(response));
          //console.log(response.data.message);
          if (response.data.success) {
            localStorage.ddPST = response.data.token;
          }
        })
        .catch((error) => {
          //this.isLoading = false;
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    checkToken() {
      if (localStorage.ddPST) {
        console.log("Checking local Token..");
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validateSearch.php`, {
            token: localStorage.ddPST,
          })
          .then((response) => {
            //console.log("RESPONCE:" + JSON.stringify(response));
            console.log(response.data.message);
            if (!response.data.success) {
              localStorage.removeItem("ddPST");
              this.getToken();
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Token Not found!");
        this.getToken();
      }
    },
  },
  mounted() {
    console.log("search proof mounted");
    console.log("Begin Token Check!");
    if (!localStorage.ddPST) {
      this.getToken();
    } else {
      console.log("Token Present!");
      this.checkToken();
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.approveProof;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "approveProof");
        return false;
      },
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
</style>
