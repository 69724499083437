import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueDragResize from "vue-drag-resize";
import VueMeta from "vue-meta";

Vue.component("vue-drag-resize", VueDragResize);
Vue.config.productionTip = false;

// Vue.prototype.$env = {
//   siteTitle: "DD Proofing",
//   siteUrl: "https://displaydynamics.ca/",
//   shareImg: "/img/share.jpg",
//   api: "https://syntho.dev/ddproof/userapi",
//   bleedsURL: "https://syntho.dev/ddproof/bleeds/",
//   proofsURL: "https://syntho.dev/ddproof/proofs/",
//   mapsURL: "https://syntho.dev/ddproof/maps/",
//   sceneAssetsURL: "https://syntho.dev/ddproof/3dassets/",
// };

Vue.prototype.$env = {
  siteTitle: "DD Proofing",
  siteUrl: "https://proofs.displaydynamics.ca/",
  shareImg: "/img/share.jpg",
  api: "https://proofs.displaydynamics.ca/userapi",
  bleedsURL: "https://proofs.displaydynamics.ca/bleeds/",
  proofsURL: "https://proofs.displaydynamics.ca/proofs/",
  mapsURL: "https://proofs.displaydynamics.ca/maps/",
  sceneAssetsURL: "https://proofs.displaydynamics.ca/3dassets/",
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});

Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
